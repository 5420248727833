<template>
  <div class="fulfillment">
    <order-info-card-component
      :title="title"
      class="right"
    >
      <order-info-card-item-component
        v-for="(item, index) in fulfillment.fulfillmentLineItems"
        :key="item.lineItem.id"
        class="fulfillment__product"
        :bordered="index !== fulfillment.fulfillmentLineItems.length - 1"
      >
        <template #icon>
          <div class="pl-2">
            <img
              :width="50"
              :height="50"
              :src="item.lineItem.image"
              class="fulfillment__product__image"
            />
          </div>
        </template>
        <template #body>
          <div class="fulfillment__product__body">
            <div class="fulfillment__product__body__name">
              <div>
                <router-link :to="getProductLink(item.lineItem.product.id)">
                  {{ item.lineItem.product.title }}
                </router-link>
              </div>
              <div>{{ item.lineItem.variantTitle }}</div>
            </div>
            <div class="fulfillment__product__body__quantity">
              {{ item.lineItem.quantity }}
            </div>
          </div>
        </template>
        <template #actions>
          <div class="fulfillment__product__actions">
            <div>${{ item.lineItem.originalTotalSet }}</div>
            <div>${{ `${item.lineItem.originalUnitPriceSet}x${item.lineItem.quantity}` }}</div>
          </div>
        </template>
      </order-info-card-item-component>
    </order-info-card-component>

    <div class="fulfillment__shipping">
      <order-info-card-component
        :spaced="false"
        class="right"
      >
        <order-info-card-item-component
          class="fulfillment__shipping__item"
          actions-bottom
        >
          <template #icon>
            <div class="ml-4">
              <span class="fulfillment__shipping__item__icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6667 2.23926C10.9111 2.23926 11.1111 2.43921 11.1111 2.68359V6.23828H12.4444V2.68359C12.4444 1.70328 11.6472 0.90625 10.6667 0.90625H5.33333C4.35278 0.90625 3.55556 1.70328 3.55556 2.68359V6.23828H4.88889V2.68359C4.88889 2.43921 5.08889 2.23926 5.33333 2.23926H6.88889V4.0166C6.88889 4.26099 7.08889 4.46094 7.33333 4.46094H8.66667C8.91111 4.46094 9.11111 4.26099 9.11111 4.0166V2.23926H10.6667ZM9.1 15.125H14.2222C15.2028 15.125 16 14.328 16 13.3477V8.9043C16 7.92398 15.2028 7.12695 14.2222 7.12695H9.1C9.42778 7.49353 9.65833 7.95175 9.74167 8.45996H10.4444V10.2373C10.4444 10.4817 10.6444 10.6816 10.8889 10.6816H12.2222C12.4667 10.6816 12.6667 10.4817 12.6667 10.2373V8.45996H14.2222C14.4667 8.45996 14.6667 8.65991 14.6667 8.9043V13.3477C14.6667 13.592 14.4667 13.792 14.2222 13.792H9.74167C9.65556 14.3002 9.42778 14.7584 9.1 15.125ZM7.11111 8.45996C7.35556 8.45996 7.55556 8.65991 7.55556 8.9043V13.3477C7.55556 13.592 7.35556 13.792 7.11111 13.792H1.77778C1.53333 13.792 1.33333 13.592 1.33333 13.3477V8.9043C1.33333 8.65991 1.53333 8.45996 1.77778 8.45996H3.33333V10.2373C3.33333 10.4817 3.53333 10.6816 3.77778 10.6816H5.11111C5.35556 10.6816 5.55556 10.4817 5.55556 10.2373V8.45996H7.11111ZM1.77778 7.12695C0.797222 7.12695 0 7.92398 0 8.9043V13.3477C0 14.328 0.797222 15.125 1.77778 15.125H7.11111C8.09167 15.125 8.88889 14.328 8.88889 13.3477V8.9043C8.88889 7.92398 8.09167 7.12695 7.11111 7.12695H1.77778Z"
                    fill="#343434"
                  />
                </svg>
              </span>
            </div>
          </template>
          <template #body>
            <div class="fulfillment__shipping__item__body">
              <div class="fulfillment__shipping__item__body">
                <div>{{ trackingInfo.company }}</div>
                <div
                  :class="{
                    'fulfillment__shipping__item__body__number': hasTrackingInfo
                  }"
                  @click="openTrackingInfo"
                >
                  {{ trackingInfo.number }}
                </div>
              </div>
            </div>
          </template>
          <template #actions>
            <div class="fulfillment__shipping__item__actions">
              <order-status-component
                :label="fulfillment.lastStatus"
                :status="fulfillment.status"
              />
              <button
                v-if="hasTrackingInfo"
                @click="openTrackingInfo"
              >
                View Track Shipment
              </button>
            </div>
          </template>
        </order-info-card-item-component>
      </order-info-card-component>
    </div>

    <div class="fulfillment__status">
      <order-info-card-component
        :spaced="false"
        class="right"
      >
        <order-info-card-item-component
          :bordered="isStatusDetailsOpen"
          class="fulfillment__status__item"
        >
          <template #icon>
            <div class="ml-4">
              <span class="fulfillment__status__item__icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1_19020)">
                    <path
                      d="M8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.5312 6.53125C11.825 6.2375 11.825 5.7625 11.5312 5.47188C11.2375 5.18125 10.7625 5.17813 10.4719 5.47188L7.00313 8.94063L5.53438 7.47188C5.24063 7.17813 4.76562 7.17813 4.475 7.47188C4.18437 7.76562 4.18125 8.24063 4.475 8.53125L6.475 10.5312C6.76875 10.825 7.24375 10.825 7.53438 10.5312L11.5312 6.53125Z"
                      fill="#9AD1AA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_19020">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span
                v-if="isStatusDetailsOpen"
                class="line line-bottom"
              />
            </div>
          </template>
          <template #body>
            <div class="fulfillment__status__item__body">
              <div>
                {{ lastStatus.name }}
              </div>
              <div>
                {{ lastStatus.date }}
              </div>
            </div>
          </template>
          <template
            v-if="otherStatuses.length"
            #actions
          >
            <div class="fulfillment__status__item__actions">
              <button
                class="statusBtn"
                @click="isStatusDetailsOpen = !isStatusDetailsOpen"
              >
                {{ statusBtnText }}
                <i
                  class="fas"
                  :class="{ [statusBtnIcon]: true }"
                />
              </button>
            </div>
          </template>
        </order-info-card-item-component>
        <template v-if="isStatusDetailsOpen">
          <order-info-card-item-component
            v-for="(status, index) in otherStatuses"
            :key="status.name"
            :bordered="index !== otherStatuses.length -1"
            class="fulfillment__status__item"
          >
            <template #icon>
              <div class="ml-4">
                <span class="line line-top"/>

                <order-info-status-icon-component/>
                <span
                  v-if="index !==otherStatuses.length - 1"
                  class="line line-bottom"
                />
              </div>
            </template>
            <template #body>
              <div class="fulfillment__status__item__body">
                <div>
                  {{ status.name }}
                </div>
                <div>
                  {{ status.date }}
                </div>
              </div>
          </template>
          </order-info-card-item-component>
        </template>
      </order-info-card-component>
    </div>
  </div>
</template>

<script>
  import OrderStatusComponent from './order-status.component';
  import OrderInfoCardComponent from './order-info-card.component';
  import OrderInfoCardItemComponent from './order-info-card-item.component';
  import OrderInfoStatusIconComponent from '@/entities/account/features/order/components/order-info-status-icon.component.vue';

  import device from 'src/service/device-service';

  export default {
    name: 'OrderFulfillmentComponent',

    components: {
      OrderInfoStatusIconComponent,
      OrderInfoCardComponent,
      OrderInfoCardItemComponent,
      OrderStatusComponent,
    },

    props: {
      fulfillment: {
        type: Object,
        default: null,
      },
      isFirstItem: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isStatusDetailsOpen: false,
      }
    },

    computed: {
      isMobile() {
        return device.isAnyMobile();
      },
      title() {
        return this.isFirstItem ? 'Order Details' : '';
      },
      trackingInfo() {
        return this.fulfillment.trackingInfo[0] || {};
      },
      hasTrackingInfo() {
        return this.fulfillment.trackingInfo.length && this.trackingInfo.url;
      },
      statusBtnText() {
        return this.isStatusDetailsOpen ? 'Hide Details' : 'VIew Details';
      },
      statusBtnIcon() {
        return this.isStatusDetailsOpen ? 'fa-chevron-up' : 'fa-chevron-down';
      },
      lastStatus() {
        return this.fulfillment.statuses.slice(-1)[0];
      },
      otherStatuses() {
        return this.fulfillment.statuses.slice(0, -1).reverse();
      },
    },

    methods: {
      getProductLink(productId) {
        const parseProductId = productId.split('/');
        const id = parseProductId[parseProductId.length - 1];

        return {
          name: 'product-id',
          params: { id },
        };
      },
      openTrackingInfo() {
        if (this.hasTrackingInfo) {
          window.open(this.trackingInfo.url, '_blank').focus();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .right {
    margin-left: auto;
    margin-right: 0;
  }
  .line {
    border-left: 1px dashed #9AD1AA;
    height: 10px;
    position: absolute;
    left: 34px;
  }
  .line-bottom {
    bottom: 0px;
  }
  .line-top {
    top: 0px;
  }

  .ml-4 {
    margin-left: 16px!important;
  }

  .statusBtn {
    color: #4B89E5;
    font-size: 14px;
    outline: none;
  }

  .fulfillment {
    font-size: 15px;

    &__product {
      &__image {
        border-radius: 5px;
      }

      &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__name {
          padding-left: 12px;

          &>div:first-child a {
            color: #4B89E5;
          }

          &>div:last-child {
            color: #ACB6CA;
            font-size: 14px;
          }
        }

        &__quantity {
          padding-right: 5px;
        }
      }
      &__actions {
        min-width: 100px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        &>div:last-child {
          font-size: 12px;
          color: #ACB6CA;
        }
      }
    }

    &__shipping {
      &__item {
        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border: 1px solid #E7EBF1;
          border-radius: 50%;
        }
        &__body {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-left: 8px;
          &__number {
            color: #4B89E5;
            cursor: pointer;
            font-size: 14px;
          }
        }
        &__actions {
          display: flex;
          align-items: center;
          gap: 8px;
          button {
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #E7EBF1;
            border-radius: 5px;
            padding: 10px 14px;
          }
        }
      }
    }

    &__status {
      &__item {
        position: relative;
        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: #E6F8EB;
        }
        &__body {
          margin-left: 8px;
          &>div:first-child {
            font-weight: 500;
          }
          &>div:last-child {
            color: #B6C0D3;
          }
        }
      }
    }
  }
</style>