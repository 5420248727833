<template>
  <div class="order-info">
    <order-info-card-component
      title="Contact Information"
      class="order-info__column__contact"
    >
      <order-info-card-item-component bordered>
        <template #body>
          <div class="order-info__column__contact__item ml-4">
            <div class="order-info__column__contact__item__target">
              Recipient's name
            </div>
            <div class="order-info__column__contact__item__value">
              {{ `${order.customer.firstName} ${order.customer.lastName}` }}
            </div>
          </div>
        </template>
      </order-info-card-item-component>
      <order-info-card-item-component>
        <template #body>
          <div class="order-info__column__contact__item ml-4">
            <div class="order-info__column__contact__item__target">
              Email
            </div>
            <div class="order-info__column__contact__item__value">
              {{ order.customer.email }}
            </div>
          </div>
        </template>
      </order-info-card-item-component>
    </order-info-card-component>
    <order-info-card-component
      title="Payment info"
    >
      <order-info-card-item-component>
        <template #icon>
          <div class="ml-4">
            <span class="order-info__item__icon">
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Vector"
                  d="M16 1.5C16.275 1.5 16.5 1.725 16.5 2V3H1.5V2C1.5 1.725 1.725 1.5 2 1.5H16ZM16.5 6V12C16.5 12.275 16.275 12.5 16 12.5H2C1.725 12.5 1.5 12.275 1.5 12V6H16.5ZM2 0C0.896875 0 0 0.896875 0 2V12C0 13.1031 0.896875 14 2 14H16C17.1031 14 18 13.1031 18 12V2C18 0.896875 17.1031 0 16 0H2ZM3.75 9.5C3.33438 9.5 3 9.83438 3 10.25C3 10.6656 3.33438 11 3.75 11H5.25C5.66563 11 6 10.6656 6 10.25C6 9.83438 5.66563 9.5 5.25 9.5H3.75ZM7.75 9.5C7.33437 9.5 7 9.83438 7 10.25C7 10.6656 7.33437 11 7.75 11H11.25C11.6656 11 12 10.6656 12 10.25C12 9.83438 11.6656 9.5 11.25 9.5H7.75Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </template>
        <template #body>
          <div class="pl-3">
            {{ order.paymentGatewayName }}
          </div>
        </template>
        <template #actions>
          <div class="pl-3">
            <div class="order-info__item__status">
              <span
                class="order-info__item__status__dot"
                :class="{ [order.financialStatus.key]: true }"
              />
              {{ order.financialStatus.text }}
            </div>
          </div>
        </template>
      </order-info-card-item-component>
    </order-info-card-component>
    <order-info-card-component line-bottom
      title="Shipping info"
    >
      <order-info-card-item-component bordered>
        <template #icon>
          <div class="ml-4">
            <span class="order-info__item__icon">
              <svg
                width="11"
                height="14"
                viewBox="0 0 11 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.17891 13.6854C7.64844 11.9254 11 7.65967 11 5.26362C11 2.35766 8.53646 0 5.5 0C2.46354 0 0 2.35766 0 5.26362C0 7.65967 3.35156 11.9254 4.82109 13.6854C5.17344 14.1049 5.82656 14.1049 6.17891 13.6854ZM5.5 3.50908C5.98623 3.50908 6.45255 3.69393 6.79636 4.02297C7.14018 4.35201 7.33333 4.79829 7.33333 5.26362C7.33333 5.72895 7.14018 6.17523 6.79636 6.50427C6.45255 6.83331 5.98623 7.01816 5.5 7.01816C5.01377 7.01816 4.54745 6.83331 4.20364 6.50427C3.85982 6.17523 3.66667 5.72895 3.66667 5.26362C3.66667 4.79829 3.85982 4.35201 4.20364 4.02297C4.54745 3.69393 5.01377 3.50908 5.5 3.50908Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </template>
        <template #body>
          <div class="pl-3">
            {{ order.shippingLine }}
          </div>
        </template>
      </order-info-card-item-component>
      <order-info-card-item-component>
        <template #icon>
          <div class="ml-4">
            <span class="order-info__item__icon">
              <svg
                width="11"
                height="14"
                viewBox="0 0 11 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.17891 13.6854C7.64844 11.9254 11 7.65967 11 5.26362C11 2.35766 8.53646 0 5.5 0C2.46354 0 0 2.35766 0 5.26362C0 7.65967 3.35156 11.9254 4.82109 13.6854C5.17344 14.1049 5.82656 14.1049 6.17891 13.6854ZM5.5 3.50908C5.98623 3.50908 6.45255 3.69393 6.79636 4.02297C7.14018 4.35201 7.33333 4.79829 7.33333 5.26362C7.33333 5.72895 7.14018 6.17523 6.79636 6.50427C6.45255 6.83331 5.98623 7.01816 5.5 7.01816C5.01377 7.01816 4.54745 6.83331 4.20364 6.50427C3.85982 6.17523 3.66667 5.72895 3.66667 5.26362C3.66667 4.79829 3.85982 4.35201 4.20364 4.02297C4.54745 3.69393 5.01377 3.50908 5.5 3.50908Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </template>
        <template #body>
          <div class="pl-3">
            {{ order.shippingAddress }}
          </div>
        </template>
      </order-info-card-item-component>
    </order-info-card-component>
    <order-info-card-component
      title="Billing Address"
    >
      <order-info-card-item-component>
        <template #icon>
          <div class="ml-4">
            <span class="order-info__item__icon">
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Vector"
                  d="M16 1.5C16.275 1.5 16.5 1.725 16.5 2V3H1.5V2C1.5 1.725 1.725 1.5 2 1.5H16ZM16.5 6V12C16.5 12.275 16.275 12.5 16 12.5H2C1.725 12.5 1.5 12.275 1.5 12V6H16.5ZM2 0C0.896875 0 0 0.896875 0 2V12C0 13.1031 0.896875 14 2 14H16C17.1031 14 18 13.1031 18 12V2C18 0.896875 17.1031 0 16 0H2ZM3.75 9.5C3.33438 9.5 3 9.83438 3 10.25C3 10.6656 3.33438 11 3.75 11H5.25C5.66563 11 6 10.6656 6 10.25C6 9.83438 5.66563 9.5 5.25 9.5H3.75ZM7.75 9.5C7.33437 9.5 7 9.83438 7 10.25C7 10.6656 7.33437 11 7.75 11H11.25C11.6656 11 12 10.6656 12 10.25C12 9.83438 11.6656 9.5 11.25 9.5H7.75Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </template>
        <template #body>
          <div class="pl-3">
            {{ order.billingAddress }}
          </div>
        </template>
      </order-info-card-item-component>
    </order-info-card-component>
  </div>
</template>

<script>
  import OrderInfoCardComponent from './order-info-card.component';
  import OrderInfoCardItemComponent from './order-info-card-item.component';

  export default {
    name: 'OrderInfoDesctopComponent',

    components: {
      OrderInfoCardComponent,
      OrderInfoCardItemComponent,
    },

    props: {
      order: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style scoped lang="scss">
  .order-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;

    &__column {
      flex: 1;
      min-width: 320px;

      &__contact {
        &__item {
          display: flex;
          color: #343434;

          &__target {
            width: 200px;
            font-weight: 500;
          }

          &__value {
            font-weight: 400;
          }
        }
      }
    }

    &__item {
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #1079DB;
      }

      &__status {
        border: 1px solid #E7EBF1;
        border-radius: 5px;
        padding: 8px 12px;

        &__dot {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
  }

  .pl-2 {
    padding-left: 8px;
  }

  .pl-3 {
    padding-left: 12px;
  }

  .ml-4 {
    margin-left: 20px!important;
  }

  .fulfilled {
    background-color: #20b978;
  }
  .on-hold {
    background-color: #f02f30;
  }

  .in-progress,
  .open,
  .unfulfilled,
  .scheduled,
  .restocked,
  .pending-fulfillment,
  .partially-fulfilled {
    background-color: #f7de02;
  }

  .authorized,
  .partially-paid {
    background-color: #b8e8d2;
  }
  .expired,
  .refunded,
  .voided {
    background-color: #bbbbbb;
  }
  .paid {
    background-color: #10cc7d;
  }
  .partially-refunded {
    background-color: #e9e9e9;
  }
  .pending {
    background-color: #f7de02;
  }

</style>