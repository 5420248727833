<template>
  <chip-component
    :label="label"
    :dot-color="color"
  />
</template>

<script>
  import ChipComponent from '@/shared/components/chip.component.vue';

  export default {
    name: 'OrderStatusComponent',

    props: {
      label: {
        type: String,
        default: '',
      },
      status: {
        type: String,
        default: '',
      },
    },

    components: {
      ChipComponent,
    },

    computed: {
      color() {
        if ([
          'in-progress',
          'open',
          'unfulfilled',
          'scheduled',
          'restocked',
          'pending-fulfillment',
          'partially-fulfilled',
        ].includes(this.status)) {
          return '#f7de02';
        }

        if (['authorized', 'partially-paid'].includes(this.status)) {
          return '#b8e8d2';
        }

        if (['expired', 'refunded', 'voided'].includes(this.status)) {
          return '#bbbbbb';
        }

        if (this.status === 'paid') {
          return '#10cc7d';
        }

        if (this.status === 'partially-refunded') {
          return '#e9e9e9';
        }

        return '#f7de02';
      },
    }
  }
</script>