<template>
  <div class="card-info">
    <div
      class="card-info__header"
      :class="{ bordered: isOpen }"
    >
      <div class="card-info__header__title">{{ panelText }}</div>
      <div class="card-info__header__actions">
        <span
          v-if="isOpen"
          class="card-info__header__actions__toggler"
          @click="isOpen = !isOpen"
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.47012 2.47021C5.76309 2.17725 6.23887 2.17725 6.53184 2.47021L11.0318 6.97021C11.3248 7.26318 11.3248 7.73897 11.0318 8.03193C10.7389 8.3249 10.2631 8.3249 9.97012 8.03193L5.9998 4.06162L2.02949 8.02959C1.73652 8.32256 1.26074 8.32256 0.967773 8.02959C0.674805 7.73662 0.674805 7.26084 0.967773 6.96787L5.46777 2.46787L5.47012 2.47021Z" fill="#343434"/>
          </svg>
        </span>
        <span
          v-if="!isOpen"
          class="card-info__header__actions__toggler"
          @click="isOpen = !isOpen"
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1788_8782)">
              <path d="M6.52988 9.52978C6.23691 9.82275 5.76113 9.82275 5.46816 9.52978L0.968164 5.02978C0.675195 4.73682 0.675195 4.26103 0.968164 3.96807C1.26113 3.6751 1.73691 3.6751 2.02988 3.96807L6.0002 7.93838L9.97051 3.97041C10.2635 3.67744 10.7393 3.67744 11.0322 3.97041C11.3252 4.26338 11.3252 4.73916 11.0322 5.03213L6.53223 9.53213L6.52988 9.52978Z" fill="#343434"/>
            </g>
            <defs>
              <clipPath id="clip0_1788_8782">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="translate(12 12) rotate(-180)"/>
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
    </div>
    <div
      v-if="isOpen"
    >
      <div class="card-info__item bordered">
        <div class="card-info__item__title">Contact Information</div>
        <div class="card-info__item__body mt-3">
          <div class="mr-2">
            <div class="card-info__item__body__icon">
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1.5V2C8 2.55312 7.55312 3 7 3H5C4.44688 3 4 2.55312 4 2V1.5H2C1.725 1.5 1.5 1.725 1.5 2V14C1.5 14.275 1.725 14.5 2 14.5H10C10.275 14.5 10.5 14.275 10.5 14V2C10.5 1.725 10.275 1.5 10 1.5H8ZM0 2C0 0.896875 0.896875 0 2 0H10C11.1031 0 12 0.896875 12 2V14C12 15.1031 11.1031 16 10 16H2C0.896875 16 0 15.1031 0 14V2ZM5 10H7C8.38125 10 9.5 11.1187 9.5 12.5C9.5 12.775 9.275 13 9 13H3C2.725 13 2.5 12.775 2.5 12.5C2.5 11.1187 3.61875 10 5 10ZM4 7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5C6.53043 5 7.03914 5.21071 7.41421 5.58579C7.78929 5.96086 8 6.46957 8 7C8 7.53043 7.78929 8.03914 7.41421 8.41421C7.03914 8.78929 6.53043 9 6 9C5.46957 9 4.96086 8.78929 4.58579 8.41421C4.21071 8.03914 4 7.53043 4 7Z"
                  fill="#343434"
                />
              </svg>
            </div>
          </div>
          <div class="card-info__item__body__content">
            <div class="card-info__item__body__content__title">Recipient's name</div>
            <div class="card-info__item__body__content__subtitle">
              {{ `${order.customer.firstName} ${order.customer.lastName}` }}
            </div>
          </div>
        </div>
      </div>

      <div class="card-info__item bordered">
        <div class="card-info__item__body">
          <div class="mr-2">
            <div class="card-info__item__body__icon">
              <span>
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 1.5C1.725 1.5 1.5 1.725 1.5 2V2.69063L6.89062 7.11563C7.5375 7.64688 8.46562 7.64688 9.1125 7.11563L14.5 2.69063V2C14.5 1.725 14.275 1.5 14 1.5H2ZM1.5 4.63125V10C1.5 10.275 1.725 10.5 2 10.5H14C14.275 10.5 14.5 10.275 14.5 10V4.63125L10.0625 8.275C8.8625 9.25937 7.13438 9.25937 5.9375 8.275L1.5 4.63125ZM0 2C0 0.896875 0.896875 0 2 0H14C15.1031 0 16 0.896875 16 2V10C16 11.1031 15.1031 12 14 12H2C0.896875 12 0 11.1031 0 10V2Z"
                    fill="#343434"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div class="card-info__item__body__content">
            <div>Email</div>
            <div>
              {{ order.customer.email }}
            </div>
          </div>
        </div>
      </div>

      <div class="card-info__item bordered">
        <div class="card-info__item__title">Payment Details</div>
        <div class="card-info__item__body mt-3">
          <div class="mr-2">
            <div class="card-info__item__body__icon">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none" xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1788_8935)">
                  <path
                    d="M14.2222 2.1875C14.4667 2.1875 14.6667 2.38437 14.6667 2.625V3.5H1.33333V2.625C1.33333 2.38437 1.53333 2.1875 1.77778 2.1875H14.2222ZM14.6667 6.125V11.375C14.6667 11.6156 14.4667 11.8125 14.2222 11.8125H1.77778C1.53333 11.8125 1.33333 11.6156 1.33333 11.375V6.125H14.6667ZM1.77778 0.875C0.797222 0.875 0 1.65977 0 2.625V11.375C0 12.3402 0.797222 13.125 1.77778 13.125H14.2222C15.2028 13.125 16 12.3402 16 11.375V2.625C16 1.65977 15.2028 0.875 14.2222 0.875H1.77778ZM3.33333 9.1875C2.96389 9.1875 2.66667 9.48008 2.66667 9.84375C2.66667 10.2074 2.96389 10.5 3.33333 10.5H4.66667C5.03611 10.5 5.33333 10.2074 5.33333 9.84375C5.33333 9.48008 5.03611 9.1875 4.66667 9.1875H3.33333ZM6.88889 9.1875C6.51944 9.1875 6.22222 9.48008 6.22222 9.84375C6.22222 10.2074 6.51944 10.5 6.88889 10.5H10C10.3694 10.5 10.6667 10.2074 10.6667 9.84375C10.6667 9.48008 10.3694 9.1875 10 9.1875H6.88889Z"
                    fill="#343434"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1788_8935">
                    <rect width="16" height="14" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="card-info__item__body__content">
            <div class="card-info__item__body__content__subtitle">
              {{ order.paymentGatewayName }}
            </div>
          </div>
          <div class="card-info__item__body__actions">
            <order-status-component
              :label="order.financialStatus.text"
              :status="order.financialStatus.key"
            />
          </div>
        </div>
      </div>

      <div class="card-info__item bordered">
        <div class="card-info__item__title">Shipping Details</div>
        <div class="card-info__item__body mt-3">
          <div class="mr-2">
            <div class="card-info__item__body__icon">
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1788_8933)">
                  <path
                    d="M10.5 6C10.5 3.51562 8.48438 1.5 6 1.5C3.51562 1.5 1.5 3.51562 1.5 6C1.5 6.3875 1.64062 6.9875 1.97812 7.7875C2.30625 8.5625 2.77188 9.41875 3.30625 10.2844C4.19688 11.7281 5.22813 13.1219 6 14.1156C6.775 13.1219 7.80625 11.7281 8.69375 10.2844C9.22813 9.41875 9.69375 8.5625 10.0219 7.7875C10.3594 6.9875 10.5 6.3875 10.5 6ZM12 6C12 8.73125 8.34375 13.5938 6.74062 15.6C6.35625 16.0781 5.64375 16.0781 5.25938 15.6C3.65625 13.5938 0 8.73125 0 6C0 2.6875 2.6875 0 6 0C9.3125 0 12 2.6875 12 6ZM7 6C7 5.73478 6.89464 5.48043 6.70711 5.29289C6.51957 5.10536 6.26522 5 6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6C5 6.26522 5.10536 6.51957 5.29289 6.70711C5.48043 6.89464 5.73478 7 6 7C6.26522 7 6.51957 6.89464 6.70711 6.70711C6.89464 6.51957 7 6.26522 7 6ZM3.5 6C3.5 5.33696 3.76339 4.70107 4.23223 4.23223C4.70107 3.76339 5.33696 3.5 6 3.5C6.66304 3.5 7.29893 3.76339 7.76777 4.23223C8.23661 4.70107 8.5 5.33696 8.5 6C8.5 6.66304 8.23661 7.29893 7.76777 7.76777C7.29893 8.23661 6.66304 8.5 6 8.5C5.33696 8.5 4.70107 8.23661 4.23223 7.76777C3.76339 7.29893 3.5 6.66304 3.5 6Z"
                    fill="#343434"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1788_8933">
                    <rect width="12" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="card-info__item__body__content">
            <div class="card-info__item__body__content__title">
              {{ order.shippingLine }}
            </div>
            <div class="card-info__item__body__content__subtitle">
              {{ order.shippingAddress }}
            </div>
          </div>
        </div>
      </div>

      <div class="card-info__item bordered">
        <div class="card-info__item__title">Billing Address</div>
        <div class="card-info__item__body mt-3">
          <div class="mr-2">
            <div class="card-info__item__body__icon">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none" xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1788_8935)">
                  <path
                    d="M14.2222 2.1875C14.4667 2.1875 14.6667 2.38437 14.6667 2.625V3.5H1.33333V2.625C1.33333 2.38437 1.53333 2.1875 1.77778 2.1875H14.2222ZM14.6667 6.125V11.375C14.6667 11.6156 14.4667 11.8125 14.2222 11.8125H1.77778C1.53333 11.8125 1.33333 11.6156 1.33333 11.375V6.125H14.6667ZM1.77778 0.875C0.797222 0.875 0 1.65977 0 2.625V11.375C0 12.3402 0.797222 13.125 1.77778 13.125H14.2222C15.2028 13.125 16 12.3402 16 11.375V2.625C16 1.65977 15.2028 0.875 14.2222 0.875H1.77778ZM3.33333 9.1875C2.96389 9.1875 2.66667 9.48008 2.66667 9.84375C2.66667 10.2074 2.96389 10.5 3.33333 10.5H4.66667C5.03611 10.5 5.33333 10.2074 5.33333 9.84375C5.33333 9.48008 5.03611 9.1875 4.66667 9.1875H3.33333ZM6.88889 9.1875C6.51944 9.1875 6.22222 9.48008 6.22222 9.84375C6.22222 10.2074 6.51944 10.5 6.88889 10.5H10C10.3694 10.5 10.6667 10.2074 10.6667 9.84375C10.6667 9.48008 10.3694 9.1875 10 9.1875H6.88889Z"
                    fill="#343434"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1788_8935">
                    <rect width="16" height="14" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="card-info__item__body__content">
            <div class="card-info__item__body__content__subtitle">
              {{ order.billingAddress }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import OrderStatusComponent from './order-status.component';

  export default {
    name: 'OrderInfoMobileComponent',

    components: {
      OrderStatusComponent,
    },

    props: {
      order: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        isOpen: false,
      };
    },

    computed: {
      panelText() {
        return this.isOpen ? 'Hide Order Information' : 'Show Order Information';
      },
    },
  }
</script>

<style scoped lang="scss">
  .bordered {
    border-bottom: 1px solid #EFF4F9;
  }
  .mr-2 {
    margin-right: 12px;
  }
  .mt-3 {
    margin-top: 16px;
  }
 .mt-4 {
    margin-top: 20px;
  }
  .card-info {
    border: 1px solid #E7EBF1;
    border-radius: 5px;
    margin-top: 12px;
    &__header {
      display: flex;
      justify-content: space-between;
      &__title {
        padding: 20px 16px;
        font-size: 15px;
        font-weight: 500;
      }
      &__actions {
        display: flex;
        align-items: center;
        &__toggler {
          padding: 4px 16px;
          border-left: 1px solid #E7EBF1;
        }
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      padding: 16px;
      &__title {
        font-size: 15px;
        font-weight: 500;
      }
      &__body {
        display: flex;
        align-items: center;
        &__icon {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background: #E5EEF6;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            display: flex;
          }
        }
        &__content {
          font-size: 15px;
          &__title {
            color: #343434;
            font-weight: 500;
          }
          &__subtitle {}
        }
        &__actions {
          margin-left: auto;
        }
      }
    }
  }
</style>