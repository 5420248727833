<template>
  <div class="order-return-request-item">
    <div class="order-return-request-item__header">
      Return Request
      <span class="order-return-request-item__header__name">
        {{ returnRequest.name }}
      </span>
    </div>
    <div class="order-return-request-item__body">
      <div
        v-if="isNonRequestedStatus"
        class="order-return-request-item__body__status"
      >
        <div class="order-return-request-item__body__status__wrap__icon">
          <order-info-status-icon-component class="order-return-request-item__body__status__icon"/>
          <span class="order-return-request-item__body__status__wrap__icon__border"></span>
        </div>
        <div class="order-return-request-item__body__status__info">
          <div class="order-return-request-item__body__status__info__title">
            {{ status.title }}
          </div>
          <div class="order-return-request-item__body__status__info__description">
            {{ status.text }}
          </div>
        </div>
      </div>
      <div>
      </div>
      <div class="order-return-request-item__body__requested">
        <div>
          <order-info-status-icon-component class="order-return-request-item__body__requested__icon"/>
        </div>
        <div>
          <div class="order-return-request-item__body__requested__title">
            Return Requested
          </div>
          <div>
            Your return requested was sent and is being reviewed.
            We`ll email you once it`s been completed.
          </div>
          <div
            v-for="returnLineItem in returnRequest.lineItems"
            :key="returnLineItem.id"
            class="order-return-request-item__body__requested__product"
          >
            <div class="order-return-request-item__body__requested__product__poster">
              <img
                :width="40"
                :src="returnLineItem.item.image"
              />
            </div>
            <div class="order-return-request-item__body__requested__product__info">
              <div>
                <div class="order-return-request-item__body__requested__product__info__title">
                  {{ returnLineItem.item.product.title }}
                </div>
                <div class="order-return-request-item__body__requested__product__info__variant">
                  {{ returnLineItem.item.variantTitle }}
                </div>
              </div>
              <div class="order-return-request-item__body__requested__product__quantity">
                {{ returnLineItem.quantity }}
              </div>
            </div>
            <div class="order-return-request-item__body__requested__product__price">
              <div class="order-return-request-item__body__requested__product__price__total">
                {{ totalForLineItem(returnLineItem) }}
              </div>
              <div class="order-return-request-item__body__requested__product__price__detailed">
                {{ formatPrice(returnLineItem.item.originalUnitPriceSet) }} x {{ returnLineItem.quantity }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import OrderInfoStatusIconComponent
    from '@/entities/account/features/order/components/order-info-status-icon.component.vue';

  const statuses = {
    REQUESTED: {
      value: 'REQUESTED',
      text: 'The return was requested',
    },
    OPEN: {
      value: 'OPEN',
      title: 'Return in progress',
      text: 'The return is in progress',
    },
    CLOSED: {
      value: 'CLOSED',
      title: 'Return in completed',
      text: 'The return has been completed',
    },
    DECLINED: {
      value: 'DECLINED',
      title: 'Return is declined',
      text: 'The return has been declined',
    },
    CANCELED: {
      value: 'CANCELED',
      title: 'Return is canceled',
      text: 'The return has been canceled',
    },
  };
  export default {
    name: 'OrderReturnRequestItemComponent',
    components: { OrderInfoStatusIconComponent },

    props: {
      returnRequest: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      isNonRequestedStatus() {
        return this.returnRequest.status !== statuses.REQUESTED.value;
      },
      status() {
        return statuses[this.returnRequest.status];
      },
    },

    methods: {
      totalForLineItem(lineItem) {
        return this.formatPrice(+lineItem.item.originalUnitPriceSet * +lineItem.quantity);
      },
      formatPrice(price) {
        return `$${(Number(price)).toFixed(2)}`
      },
    },
  };
</script>

<style lang="scss" scoped>
  .order-return-request-item {
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
    max-width: 580px;
    border: 1px solid #E8EDF1;
    border-radius: 5px;

    &__header {
      font-size: 15px;
      font-weight: 500;
      padding: 0 10px 10px 10px;
      border-bottom: 1px solid #EFF4F9;
      margin: 0 -10px 10px -10px;
      &__name {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__body {
      &__status {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #EFF4F9;

        &__wrap__icon {
          position: relative;
          &__border {
            position: absolute;
            border-left: 1px dashed #9AD1AA;
            left: 18px;
            bottom: -25px;
            height: 28px;
          }
        }
        &__icon {
          margin-top: 5px;
          margin-right: 10px;
        }

        &__info {
          &__title {
            font-weight: 500;
          }

          &__description {

          }
        }
      }

      &__requested {
        display: flex;
        font-size: 15px;
        &__title {
          font-weight: 500;
        }

        &__product {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          &__poster {
            margin-right: 1rem;
            img {
              border-radius: 5px;
            }
          }

          &__quantity {
            font-size: 14px;
          }

          &__info {
            display: flex;
            justify-content: space-between;
            flex: 2;

            &__title {

            }

            &__variant {
              font-size: 14px;
              color: #ACB6CA;
            }
          }

          &__price {
            width: 7rem;
            text-align: right;
            &__detailed {
              font-size: 12px;
              color: #ACB6CA;
            }
          }
        }

        &__icon {
          margin-top: 5px;
          margin-right: 10px;
        }
      }
    }
  }
</style>
