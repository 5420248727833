<template>
  <div
    v-if="isOrderPresent"
    class="order-details"
  >
    <div class="order-details__column">
      <order-info-mobile-component
        v-if="isMobile"
        :order="order"
      />
      <order-info-desktop-component
        v-else
        :order="order"
      />
    </div>

    <div class="order-details__column">
      <order-info-card-component
        class="right"
        title="Order Details"
      >
        <div class="order-details__column__pricing">
          <div class="order-details__column__pricing__item">
            <div>Products</div>
            <div>${{ order.subtotalPriceSet }}</div>
          </div>
          <div class="order-details__column__pricing__item">
            <div>Delivery</div>
            <div>${{ order.totalShippingPriceSet }}</div>
          </div>
          <div class="order-details__column__pricing__item">
            <div>Discount</div>
            <div>-${{ order.totalDiscountsSet }}</div>
          </div>
          <div class="order-details__column__pricing__item">
            <div>Estimated taxes</div>
            <div>${{ order.totalTaxSet }}</div>
          </div>
          <div class="order-details__column__pricing__item">
            <div>Total</div>
            <div>${{ order.totalPriceSet }}</div>
          </div>
        </div>
      </order-info-card-component>

      <div class="order-details__column__details">
        <order-return-request-item-component
          v-for="returnRequest in returnRequests"
          :key="returnRequest.id"
          :return-request="returnRequest"
          class="order-details__column__details__return-item"
        />
        <div
          v-if="hasFulfillments"
        >
          <order-fulfillment-component
            v-for="(fulfillment, index) in order.fulfillments"
            :key="fulfillment.id"
            :fulfillment="fulfillment"
            :is-first-item="index === 0"
          />


          <order-info-card-component
            v-if="unfulfilledItems.length"
            title="Unfulfilled"
            class="right"
          >
            <order-info-card-item-component
              v-for="(lineItem, index) in unfulfilledItems"
              :key="lineItem.id"
              class="order-details__column__details__item"
              :class="{ 'order-details__column__details__item__bordered': index !== unfulfilledItems.length - 1 }"
            >
              <template #icon>
                <div class="pl-2">
                  <img
                    :width="50"
                    :height="50"
                    :src="lineItem.image.url"
                  />
                </div>
              </template>
              <template #body>
                <div class="order-details__column__details__item__body">
                  <div class="order-details__column__details__item__body__name">
                    <div>
                      <router-link :to="getProductLink(lineItem.product)">
                        {{ lineItem.product.title }}
                      </router-link>
                    </div>
                    <div>{{ lineItem.variantTitle }}</div>
                  </div>
                  <div class="order-details__column__details__item__body__quantity">
                    {{ lineItem.quantity }}
                  </div>
                </div>
              </template>
              <template #actions>
                <div class="order-details__column__details__item__actions">
                  <div>${{ lineItem.originalTotalSet.shopMoney.amount }}</div>
                  <div>${{ `${lineItem.originalUnitPriceSet.shopMoney.amount}x${lineItem.quantity}` }}</div>
                </div>
              </template>
            </order-info-card-item-component>
          </order-info-card-component>
        </div>
        <div v-else>
          <order-info-card-component class="right">
            <order-info-card-item-component
              v-for="(lineItem, index) in order.lineItems"
              :key="lineItem.id"
              class="order-details__column__details__item"
              :class="{ 'order-details__column__details__item__bordered': index !== order.lineItems.length - 1 }"
            >
              <template #icon>
                <div class="pl-2">
                  <img
                    :width="50"
                    :height="50"
                    :src="lineItem.image"
                  />
                </div>
              </template>
              <template #body>
                <div class="order-details__column__details__item__body">
                  <div class="order-details__column__details__item__body__name">
                    <div>
                      <router-link :to="getProductLink(lineItem.product)">
                        {{ lineItem.product.title }}
                      </router-link>
                    </div>
                    <div>{{ lineItem.variantTitle }}</div>
                  </div>
                  <div class="order-details__column__details__item__body__quantity">
                    {{ lineItem.quantity }}
                  </div>
                </div>
              </template>
              <template #actions>
                <div class="order-details__column__details__item__actions">
                  <div>${{ lineItem.originalTotalSet }}</div>
                  <div>${{ `${lineItem.originalUnitPriceSet}x${lineItem.quantity}` }}</div>
                </div>
              </template>
            </order-info-card-item-component>
          </order-info-card-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import OrderInfoDesktopComponent from './order-info-desktop.component.vue';
  import OrderInfoMobileComponent from './order-info-mobile.component.vue';
  import OrderInfoCardComponent from './order-info-card.component';
  import OrderInfoCardItemComponent from './order-info-card-item.component';
  import OrderFulfillmentComponent from './order-fulfillment.component';
  import OrderReturnRequestItemComponent from '@/entities/account/features/order/components/order-return-request-item.component.vue';

  import device from 'src/service/device-service';

  export default {
    name: 'OrderDetailsComponent',

    components: {
      OrderReturnRequestItemComponent,
      OrderInfoCardComponent,
      OrderInfoCardItemComponent,
      OrderFulfillmentComponent,
      OrderInfoDesktopComponent,
      OrderInfoMobileComponent,
    },

    props: {
      order: {
        type: Object,
        default: null,
      },
      unfulfilledItems: {
        type: Array,
        default: () => ([]),
      },
    },

    computed: {
      returnRequests() {
        return this.order?.returns || [];
      },
      isOrderPresent() {
        return Object.keys(this.order).length;
      },
      hasFulfillments() {
        return !!this.order.fulfillments.length;
      },
      isMobile() {
        return device.isAnyMobile();
      },
    },

    methods: {
      getProductLink(product) {
        return {
          name: 'product-id',
          params: { id: product.handle },
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .right {
    margin-left: auto;
    margin-right: 0;
  }
  .order-details {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;

    &__column {
      flex: 1;
      min-width: 320px;

      &__contact {
        &__item {
          display: flex;
          color: #343434;

          &__target {
            width: 200px;
            font-weight: 500;
          }

          &__value {
            font-weight: 400;
          }
        }
      }

      &__pricing {
        font-size: 15px;
        font-weight: 400;

        &>div:last-child {
          font-weight: 500;
          border-top: 1px solid #E7EBF1;
          padding: 16px 20px;
        }

        &>div:first-child {
          padding-top: 20px;
        }

        &>div:nth-child(4) {
          padding-bottom: 20px;
        }

        &__item {
          display: flex;
          flex-direction: row;
          padding: 4px 20px;
          &>div:first-child {
            flex: 4;
          }
          &>div:last-child {
            display: flex;
            flex: 1;
            justify-content: end;
          }

        }
      }

      &__details {
        &__return-item {
          margin-top: 20px;
        }

        &__item {
          img {
            border-radius: 3px;
          }
          &__bordered {
            border: 1px solid #EFF4F9;
          }
          &__body {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__name {
              padding-left: 12px;

              &>div:first-child a {
                color: #4B89E5;
              }

              &>div:last-child {
                color: #ACB6CA;
                font-size: 14px;
              }
            }

            &__quantity {
              padding-right: 5px;
            }
          }
          &__actions {
            min-width: 100px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            &>div:last-child {
              font-size: 12px;
              color: #ACB6CA;
            }
          }
        }
      }
    }

    &__item {
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #1079DB;
      }

      &__status {
        border: 1px solid #E7EBF1;
        border-radius: 5px;
        padding: 8px 12px;

        &__dot {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
  }

  .pl-2 {
    padding-left: 8px;
  }

  .pl-3 {
    padding-left: 12px;
  }

  .ml-4 {
    margin-left: 20px!important;
  }

  .fulfilled {
    background-color: #20b978;
  }
  .on-hold {
    background-color: #f02f30;
  }

  .in-progress,
  .open,
  .unfulfilled,
  .scheduled,
  .restocked,
  .pending-fulfillment,
  .partially-fulfilled {
    background-color: #f7de02;
  }

  .authorized,
  .partially-paid {
    background-color: #b8e8d2;
  }
  .expired,
  .refunded,
  .voided {
    background-color: #bbbbbb;
  }
  .paid {
    background-color: #10cc7d;
  }
  .partially-refunded {
    background-color: #e9e9e9;
  }
  .pending {
    background-color: #f7de02;
  }

</style>