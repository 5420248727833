<template>
  <div
    class="card"
    :class="{spaced}"
  >
    <div class="card__title">
      {{ title }}
    </div>
    <div class="card__content">
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrderInfoCardComponent',

    props: {
      title: {
        type: String,
        default: '',
      },
      spaced: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style scoped lang="scss">
   .spaced {
    padding-top: 32px;
   }
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 580px;

    &__title {
      color: #343434;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 6px;
    }

    &__content {
      border: 1px solid #E8EDF1;
      border-radius: 5px;
    }
  }
</style>