<template>
  <order-container/>
</template>

<script>
  import OrderContainer from './order.container';

  export default {
    name: 'OrderView',

    components: {
      OrderContainer,
    },
  }
</script>