<template>
  <div class="order">
    <div class="order__header">
      <order-header
        :order="order"
        :is-multiple-shipments="isMultipleShipments"
        :show-return="showReturn"
        :disabled-return-reason="disabledReturnReason"
        @order:request-return="onRequestReturn"
      />
    </div>
    <div class="order__details">
      <order-details :order="order" :unfulfilled-items="unfulfilledItems" />
    </div>
  </div>
</template>

<script>
  import Auth from '@/service/authService';

  import OrderApi from './api';
  import OrderHeader from './components/order-header.component';
  import OrderDetails from './components/order-details.component';
  import {
    normalizeOrder,
    normalizeUnfulfilledItems,
  } from './helpers/normalization.helper';
  import RequestReturnApi from '@/entities/account/features/request-return/api';

  export default {
    name: 'OrderContainer',
    components: {
      OrderHeader,
      OrderDetails,
    },

    data() {
      return {
        loading: true,

        pagination: {
          count: 20,
          page: '',
        },

        order: {},
        orderProducts: [],
        returnableFulfillments: [],
        unfulfilledItems: [],
      };
    },

    computed: {
      returnRequests() {
        return this.order?.returns || [];
      },
      disabledReturnReason() {
        if (this.returnRequests.length < 1 || this.hasReturnableFulfillments) {
          return '';
        }

        if (this.returnRequests.every((el) => el.status === 'CLOSED')) {
          return 'Return completed';
        }

        return 'Return in progress';
      },
      orderId() {
        return this.$route.params.id;
      },
      shopifyId() {
        return `gid://shopify/Order/${this.orderId}`;
      },
      isMultipleShipments() {
        return this.order.fulfillments && this.order.fulfillments.length > 1;
      },
      currentUser() {
        return Auth.get('user');
      },
      showReturn() {
        return this.returnRequests.length > 0 || this.hasReturnableFulfillments;
      },
      hasReturnableFulfillments() {
        return (
          this.hasFulfillments &&
          this.returnableFulfillments.length > 0 &&
          this.returnableFulfillments.map((rf) => rf.items).flat().length > 0
        );
      },
      hasFulfillments() {
        return this.order.fulfillments && this.order.fulfillments.length > 0;
      },
    },

    async mounted() {
      await this.fetchOrder();
      await this.fetchFulfillmentOrders();
      this.loadLoadItemsForReturning();

      this.$bus.$emit('updateTitle', `Order ${this.order.name}`);
    },

    methods: {
      async loadLoadItemsForReturning() {
        try {
          const items = await RequestReturnApi.getReturnableFulfillments(
            this.orderId,
          );
          this.returnableFulfillments = items;
        } catch (err) {
          console.log('err', err);
        }
      },
      onRequestReturn() {
        this.$router.push({
          name: 'request-return',
          params: { orderId: this.orderId },
        });
      },
      async fetchOrder() {
        this.setCountForDifferentWindowSize();

        this.loading = true;

        try {
          const options = {
            count: this.pagination.count,
            ...(this.pagination.page ? { page: this.pagination.page } : {}),
          };

          const response = await OrderApi.getOrder(this.shopifyId, options);
          const order = normalizeOrder(response);

          if (this.currentUser.privateId !== order.customer.email) {
            this.$router.push({ name: 'orders' });
          }

          this.order = order;
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },

      async fetchFulfillmentOrders() {
        try {
          const response = await OrderApi.getFulfillmentOrders({ id: this.shopifyId });
          this.unfulfilledItems = normalizeUnfulfilledItems(response);
        } catch (err) {
          console.log(err);
        }
      },

      async onIntersect() {
        if (!this.pagination?.hasNextPage) {
          return;
        }

        await this.fetchOrderById();
      },

      setCountForDifferentWindowSize() {
        if (window.innerWidth <= 1800 && window.innerWidth > 1250) {
          this.pagination.count = 18;
        } else if (window.innerWidth <= 1250 && window.innerWidth > 800) {
          this.pagination.count = 16;
        } else if (window.innerWidth <= 800) {
          this.pagination.count = 12;
        } else {
          this.pagination.count = 20;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .order {
  }
</style>
