<template>
  <div
    v-if="isOrderPresent"
    class="order-header"
  >
    <div class="order-header__info">
      <div
        class="order-header__info__details"
        :class="{ row: !isMobile, column: isMobile }"
      >
        <div>
          <div class="order-header__info__details__info">
            <div
              class="order-header__info__details__back"
              @click="goBack"
            >
              <i class="fas fa-chevron-left"/>
            </div>
            <div class="order-header__info__details__name">
              Order {{ order.name }}
            </div>
          </div>
          <div class="order-header__info__approved">
            Created {{ order.processedAt }}
          </div>
        </div>
        <div class="order-header__info__details__statuses">
          <div
            v-if="isMultipleShipments"
            class="order-header__info__details__status"
          >
            <span
              class="order-header__info__details__status__dot paid"
            />
            Multiple Shipments
          </div>
          <div class="order-header__info__details__status">
            <span
              class="order-header__info__details__status__dot"
              :class="{ [order.financialStatus.key]: true }"
            />
            {{ order.financialStatus.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="order-header__actions">
      <b-dropdown
        v-if="isMobile"
        id="dropdown-left"
        variant="link"
        class="product-actions"
        no-caret
      >
        <template #button-content>
          <svg
            width="4"
            height="18"
            viewBox="0 0 4 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 4C2.39556 4 2.78224 3.8827 3.11114 3.66294C3.44004 3.44318 3.69639 3.13082 3.84776 2.76537C3.99914 2.39992 4.03874 1.99778 3.96157 1.60982C3.8844 1.22186 3.69392 0.865492 3.41421 0.585787C3.13451 0.306082 2.77814 0.115601 2.39018 0.0384303C2.00222 -0.0387401 1.60009 0.000866562 1.23463 0.152242C0.869182 0.303617 0.556825 0.559962 0.337062 0.88886C0.117299 1.21776 1.07779e-06 1.60444 1.07779e-06 2C1.07779e-06 2.53043 0.210715 3.03914 0.585788 3.41421C0.960861 3.78929 1.46957 4 2 4ZM2 14C1.60444 14 1.21776 14.1173 0.888861 14.3371C0.559963 14.5568 0.303617 14.8692 0.152242 15.2346C0.000866562 15.6001 -0.0387401 16.0022 0.0384303 16.3902C0.115601 16.7781 0.306083 17.1345 0.585788 17.4142C0.865493 17.6939 1.22186 17.8844 1.60982 17.9616C1.99778 18.0387 2.39992 17.9991 2.76537 17.8478C3.13082 17.6964 3.44318 17.44 3.66294 17.1111C3.8827 16.7822 4 16.3956 4 16C4 15.4696 3.78929 14.9609 3.41421 14.5858C3.03914 14.2107 2.53043 14 2 14ZM2 7C1.60444 7 1.21776 7.1173 0.888861 7.33706C0.559963 7.55682 0.303617 7.86918 0.152242 8.23463C0.000866562 8.60009 -0.0387401 9.00222 0.0384303 9.39018C0.115601 9.77814 0.306083 10.1345 0.585788 10.4142C0.865493 10.6939 1.22186 10.8844 1.60982 10.9616C1.99778 11.0387 2.39992 10.9991 2.76537 10.8478C3.13082 10.6964 3.44318 10.44 3.66294 10.1111C3.8827 9.78224 4 9.39556 4 9C4 8.46957 3.78929 7.96086 3.41421 7.58579C3.03914 7.21071 2.53043 7 2 7Z"
              fill="#343434"
            />
          </svg>
        </template>
        <b-dropdown-item
          v-if="showReturn"
          :title="disabledReturnReason"
          @click="onRequestReturn"
        >
          Return
        </b-dropdown-item>
        <b-dropdown-item>
          Buy Again
        </b-dropdown-item>
      </b-dropdown>

      <div v-else>
        <button
          v-if="showReturn"
          class="default"
          :title="disabledReturnReason"
          :disabled="!!disabledReturnReason"
          @click="onRequestReturn"
        >
          Return
        </button>
        <button class="primary">
          Buy Again
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import device from 'src/service/device-service';
  import ButtonComponent from '@/shared/components/button.component.vue';

  export default {
    name: 'OrderHeaderComponent',
    components: { ButtonComponent },

    props: {
      order: {
        type: Object,
        default: () => ({}),
      },
      isMultipleShipments: {
        type: Boolean,
        default: false,
      },
      showReturn: {
        type: Boolean,
        default: false,
      },
      disabledReturnReason: {
        type: String,
        default: '',
      },
    },

    computed: {
      isOrderPresent() {
        return Object.keys(this.order).length;
      },
      isMobile() {
        return device.isAnyMobile();
      },
    },

    methods: {
      onRequestReturn() {
        this.$emit('order:request-return');
      },
      goBack() {
        this.$router.push({
          name: 'orders',
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  button {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 20px;
    border-radius: 5px;

    &.default {
      color: #343434;
      border: 1px solid #E7EBF1;
      margin-right: 8px;
      &:disabled {
        opacity: 0.5;
        border-color: #dbdbdb;
      }
    }

    &.primary {
      background-color: #21A66E;
      color: #fff;
    }
  }

  .row {
    flex-direction: row;
  }

  .column {
    flex-direction: column;
  }

  .order-header {
    display: flex;
    justify-content: space-between;

    &__info {
      display: flex;
      flex-direction: column;

      &__details {
        display: flex;
        align-items: start;
        gap: 12px;

        &__info {
          display: flex;
        }

        &__statuses {
          display: flex;
        }

        &__back {
          padding-right: 10px;
          cursor: pointer;
        }

        &__name {
          font-family: Roboto;
          font-size: 26px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
        }

        &__status {
          border: 1px solid #E7EBF1;
          border-radius: 5px;
          padding: 5px 12px;
          margin-right: 4px;
          font-size: 14px;

          &__dot {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }

      &__approved {
        color: #B6C0D3;
        font-size: 14px;
      }
    }

    &__actions {
      display: flex;
      align-items: start;
      gap: 8px;
    }
  }

  .fulfilled {
    background-color: #20b978;
  }
  .on-hold {
    background-color: #f02f30;
  }

  .in-progress,
  .open,
  .unfulfilled,
  .scheduled,
  .restocked,
  .pending-fulfillment,
  .partially-fulfilled {
    background-color: #f7de02;
  }

  .authorized,
  .partially-paid {
    background-color: #b8e8d2;
  }
  .expired,
  .refunded,
  .voided {
    background-color: #bbbbbb;
  }
  .paid {
    background-color: #10cc7d;
  }
  .partially-refunded {
    background-color: #e9e9e9;
  }
  .pending {
    background-color: #f7de02;
  }
</style>