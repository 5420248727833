<template>
  <div class="chip">
    <span
      class="chip__dot"
      :style="{ background: dotColor }"
    />
    {{ label }}
  </div>
</template>

<script>
  export default {
    name: 'ChipComponent',

    props: {
      label: {
        type: String,
        default: '',
      },
      dotColor: {
        type: String,
        default: '#4FE09D',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chip {
    height: fit-content;
    border: 1px solid #E7EBF1;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    &__dot {
      display: flex;
      width: 6px!important;
      height: 6px!important;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
</style>