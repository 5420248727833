<template>
  <div
    class="card-item"
    :class="{ 'bordered': bordered }"
  >
    <div
      v-if="isMobile && actionsBottom"
      class="card-item__mobile"
    >
      <div class="card-item__mobile__content">
        <div class="card-item__mobile__content__icon">
          <slot name="icon"/>
        </div>

        <div class="card-item__mobile__content__body">
          <slot name="body"/>
        </div>
      </div>
      <div class="card-item__mobile__actions">
        <slot name="actions"/>
      </div>
    </div>

    <div
      v-else
      class="card-item__desktop"
    >
      <div class="card-item__desktop__icon">
        <slot name="icon"/>
      </div>

      <div class="card-item__desktop__body">
        <slot name="body"/>
      </div>

      <div class="card-item__desktop__actions">
        <slot name="actions"/>
      </div>
    </div>
  </div>
</template>

<script>
  import device from 'src/service/device-service';

  export default {
    name: 'OrderInfoCardItemComponent',

    props: {
      bordered: {
        type: Boolean,
        default: false,
      },
      actionsBottom: {
        type: Boolean,
        default: false,
      }
    },

    computed: {
      isMobile() {
        return device.isAnyMobile();
      },
    },
  };
</script>

<style scoped lang="scss">

  .card-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    &__mobile {
      width: 100%;
      &__content {
        display: flex;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 1px solid #EFF4F9;
      }
      &__actions {
        display: flex;
        justify-content: end;
        align-items: center;
        margin: 12px 12px 0px 12px;
      }
    }

    &__desktop {
      width: 100%;
      display: flex;
      &__body {
        flex: 2
      }
      &__actions {
        display: flex;
        align-items: center;
        margin-right: 12px;
      }
    }
  }
  .bordered {
    border-bottom: 1px solid #EFF4F9;
  }
</style>